import { Helmet } from "react-helmet";
import Catalog from "../../components/Catalog/Catalog";
import Item from "../../components/Catalog/Item";
import Section from "../../components/Catalog/Section";
import Center from "../../components/Center";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Questions from "../../components/Questions";
import { theme } from "../../theme/theme";
import FirewoodPriceDetails from "./FirewoodPriceDetails";
import Typography from "@mui/material/Typography";

const catalog: Array<Section> = [
  {
    title: "Polttopuut",
    description: (
      <Typography variant="body2" paddingBottom={3} paddingTop={1}>
        Toimitamme polttopuuta{" "}
        <span style={{ fontWeight: "bold" }}>parittomina viikkoina</span>{" "}
        suoraan asiakkaalle sovittuun aikaan ja paikkaan{" "}
        <span style={{ fontWeight: "bold" }}>Porin seudulla</span>. Polttopuut
        myydään vain toimitettuna. Kuljetamme puut kippaavalla auton
        perävaunulla, johon mahtuu kerralla 5 heittokuutiota.,
      </Typography>
    ),
    items: [
      new Item("Koivu 25 cm", "Loppu" /*95*/, "€/irto-m\u00B3"),
      // new Item("Koivu 33 cm", 80, "€/irto-m\u00B3"),
      new Item("Kuiva koivu 33 cm", 85, "€/irto-m\u00B3"),
      new Item("Koivu 45 cm", "Loppu" /*70*/, "€/irto-m\u00B3"),
      new Item("Seka 25 cm", "Loppu" /*80*/, "€/irto-m\u00B3"),
      new Item("Kuiva seka 33 cm", 75, "€/irto-m\u00B3"),
      new Item("Seka 45 cm", "Loppu" /*60*/, "€/irto-m\u00B3"),
      new Item("Sytyketuohi", "Loppu" /*5*/, "€/laatikko", "noin 15 litraa"),
      new Item(
        "Kuorikate",
        50,
        "€/m\u00B3",
        "saatavilla huhti- ja toukokuussa vain noutomyyntinä"
      ),
    ],
    descriptionDetails: <FirewoodPriceDetails />,
  },
  /*
  {
    title: "Kuorikate",
    description:
      "Kuorikatetta on saatavilla noutomyyntinä. Ota peräkärry mukaan ja tule ostoksille!",
    items: [
      // new Item("Lehtipuu", 20, "€/m\u00B3"),
    ],
  },
  {
    title: "Sytyke",
    items: [new Item("Säkki noin 30 litraa", 5)],
  },
  */
  {
    title: "Aitatolpat",
    description:
      "Tolpat ovat kuorittu ja teroitettu toisesta päästä. Voimme myös toimittaa eri mitoissa tai halkaistuna asiakkaan tarpeiden mukaan. Raaka-aineena käytetään tiheäkasvuista kuusipuuta (ei-kyllästettyjä).",
    items: [
      new Item("180 cm \u00D8 5-7 cm", 3.5, "€/kpl"),
      new Item("180 cm \u00D8 8-10 cm", 5, "€/kpl"),
      new Item("Muu pituus ja paksuus"),
      new Item("Halkaistu"),
    ],
  },
  {
    title: "Hunaja",
    description: "Myymme tilan hunajaa suoraan kuluttajille ja kauppoihin.",
    items: [
      new Item("Pehmeä hunaja 450g", 5),
      new Item("Puristepullo 350g", 6),
      new Item("Lasipurkki 320g", 7),
      new Item("Uuden sadon hunaja 1kg", 12, "€", "elokuussa"),
      new Item("Mehiläisvaha"),
    ],
  },
  {
    title: "Palvelut",
    items: [new Item("Pölytyspalvelua kotipuutarhureille")],
  },
];

const ProductsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Viidan Tila - Tuotteet</title>
        <meta
          name="description"
          content="Myymme tilan hunajaa suoraan kuluttajille ja kauppoihin, sekä kuivaa polttopuuta suoraan asiakkaalle sovittuun aikaan ja paikkaan Porin seudulla."
        />
        <meta name="keywords" content="hunajaa, polttopuita, klapi, Pori" />
      </Helmet>
      <Header
        title="Tuotteet"
        imagePath={require("../../assets/images/honey_wide.webp")}
      />
      <Center>
        <div
          style={{
            paddingTop: theme.spacing(12),
            paddingBottom: theme.spacing(12),
          }}
        >
          <Catalog sections={catalog} />
        </div>
      </Center>
      <Questions title="Kysyttävää tuotteista tai palveluista?" />
      <Footer />
    </div>
  );
};

export default ProductsPage;
